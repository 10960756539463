import React, {useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Variables from '../global'

function PartnerCard ({ partner }) {
  return (
    <React.Fragment>
      <div className='col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2'>
        <div className='partner-card'>
          <Link className='partner-card-link' to={`/partner/${partner.id}`}>
            <div className='partner-image'>
              <img
                src={`${Variables.base_url}/api/images/thumbnail-md/${partner.image.imageUrl}`}
              />
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PartnerCard
