import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isMobile:false,
  isTablet:false
}

export const viewSettingsSlice = createSlice({
  name: 'viewSettings',
  initialState: {...initialState},
  reducers: {
    changeView: (state,action)=>{
        state.isMobile = action.payload.isMobile;
        state.isTablet = action.payload.isTablet;
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeView} = viewSettingsSlice.actions

export default viewSettingsSlice.reducer